import { InputLabel, Input, FormControl, InputAdornment } from "@mui/material";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";

const CustomTextField = ({
  fieldName,
  value: initialValue,
  inputLabel,
  sx,
  readOnly = true,
  onChange,
  fullWidth = true,
  IconToShow = null,
  multiline = false,
  rows = 2,
}) => {
  const [value, setValue] = useState(initialValue ?? "");
  const theme = useTheme();

  useEffect(() => {
    setValue(initialValue ?? "");
  }, [initialValue]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (onChange) {
        onChange(value, fieldName);
      }
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl
      variant="standard"
      fullWidth={fullWidth}
      margin="normal"
      sx={sx}
    >
      <InputLabel sx={{ marginBottom: "100px" }} htmlFor={fieldName} shrink>
        {inputLabel}
      </InputLabel>
      <Input
        id={fieldName}
        value={value ?? ""}
        readOnly={readOnly}
        onChange={readOnly ? undefined : handleChange}
        onKeyDown={readOnly ? undefined : handleKeyDown}
        multiline={multiline}
        rows={multiline ? rows : 1}
        endAdornment={
          IconToShow ? (
            <InputAdornment position="end">{IconToShow}</InputAdornment>
          ) : null
        }
        sx={{
          color:
            value === "Site non certifié"
              ? theme.palette.primary.warning
              : "inherit",
          background: !readOnly
            ? theme.palette.primary.editableBackground
            : value === "Site non certifié"
            ? theme.palette.primary.warningBackground
            : "",
          borderBottom: !readOnly
            ? `2px solid ${theme.palette.primary.borderBottom}`
            : value === "Site non certifié"
            ? `2px solid ${theme.palette.primary.warning}`
            : "",
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none", // Prevent black line on hover
            },
          "&:before, &:after": {
            borderBottom:
              !readOnly || value === "Site non certifié" ? "none" : "",
          },
        }}
      />
    </FormControl>
  );
};

export default CustomTextField;
