import React from "react";
import AppBar from "@mui/material/AppBar";
import symLogo from "../../assets/images/sym-logo.png";
import Toolbar from "@mui/material/Toolbar";
import ProfileInformation from "./ProfileInformation";
import Box from "@mui/material/Box";

const NavBar = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "#FFFFFF",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <img
          src={symLogo}
          style={{ width: "150px", height: "auto" }}
          alt="Logo"
        />
        <Box sx={{ flexGrow: 1 }} />
        <ProfileInformation />
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;