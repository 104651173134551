import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CardHeader,
  IconButton,
  InputLabel,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getTime } from "../../../utils/dateUtils";
import CustomTextField from "../../layout/CustomTextField";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import KeyboardCommandKeyRoundedIcon from "@mui/icons-material/KeyboardCommandKeyRounded";
import CodeRoundedIcon from "@mui/icons-material/CodeRounded";

const Enedis = ({ data }) => {
  const theme = useTheme();

  const getValueOrDefault = (value) =>
    value !== undefined && value !== null ? value : "-";

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#b8c494",
          color: theme.palette.primary.main,
          height: "50px",
        }}
        title="Informations ENEDIS"
        titleTypographyProps={{ variant: "h6", fontSize: "0.875rem" }}
        action={
          <Box display="flex" alignItems="center">
            <IconButton aria-label="cached" variant="circled">
              <CachedRoundedIcon
                sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
              />
            </IconButton>
            <IconButton variant="circled" aria-label="refresh">
              <KeyboardCommandKeyRoundedIcon
                sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
              />
            </IconButton>
            <IconButton aria-label="code" variant="circled">
              <CodeRoundedIcon
                sx={{ color: theme.palette.primary.main, fontSize: "1rem" }}
              />
            </IconButton>
          </Box>
        }
      />
      <CardContent>
        {/* Section des informations contractuelles */}
        <Typography
          variant="h7"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold", marginBottom: 2 }}
        >
          Informations contractuelles
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 3,
          }}
        >
          <CustomTextField
            id="contractual_state"
            value={data["contractual_state"] ?? ""}
            inputLabel="État Enedis"
            sx={{ flex: 1 }}
          />
          <CustomTextField
            id="enedis_segment"
            value={data["enedis_segment"] ?? ""}
            inputLabel="Segment"
            sx={{ flex: 1 }}
          />
          <CustomTextField
            id="counting_type"
            value={data["counting_type"] ?? ""}
            inputLabel="Compteur"
            sx={{ flex: 1 }}
          />
          <CustomTextField
            id="meter_serial_number"
            value={data["meter_serial_number"] ?? ""}
            inputLabel="N° de série"
            sx={{ flex: 1 }}
          />
        </Box>
        <Box sx={{ mt: 4 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 3,
          }}
        >
          <CustomTextField
            id="max-subscribed-power"
            value={data["max_subscribed_power"] ?? ""}
            inputLabel="Puissance Souscrite (kVa)"
            sx={{ flex: 1 }}
          />
          <CustomTextField
            id="pricing-option"
            value={data["pricing_option"] ?? ""}
            inputLabel="Option tarifaire"
            sx={{ flex: 1 }}
          />
          <CustomTextField
            id="injection-power-presence"
            value={data?.injection_power_presence ? "Yes" : "No"}
            inputLabel="Injection sur les 3 dernières années"
            sx={{ flex: 1 }}
          />
        </Box>
        <Box sx={{ mt: 4 }} />
        <CustomTextField
          id="calendar"
          value={data?.frn_calendar_label ?? ""}
          inputLabel="Calendrier"
        />
        <Box sx={{ mt: 4 }} />
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink>Plage(s) d'heures creuses actuelles</InputLabel>
          {data.off_peak_start?.map((startTime, index) => (
            <List
              sx={{ padding: 0, marginTop: 1, paddingLeft: "10px" }}
              key={index}
            >
              <ListItem disableGutters>
                <ListItemText
                  primary={`• ${getTime(startTime)} - ${getTime(
                    data.off_peak_end[index]
                  )}`}
                  primaryTypographyProps={{
                    fontSize: "0.875rem",
                  }}
                />
              </ListItem>
            </List>
          ))}
        </FormControl>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <InfoOutlinedIcon
            sx={{
              color: "#b8c494",
              fontSize: "2rem",
              paddingTop: "10px",
            }}
          />
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="enedis-offpeak-hours" shrink>
              Future plage d'heures creuses
            </InputLabel>
          </FormControl>
        </Box>
        <hr style={{ backgroundColor: "#b8c494" }} /> <Box sx={{ mt: 4 }} />
        <CustomTextField
          id="formule"
          value={data?.routing_tariff_formula_label ?? ""}
          inputLabel="Formule"
          sx={{ mb: 4 }}
          multiline={true}
        />
      </CardContent>
    </Card>
  );
};

export default Enedis;
