import React from "react";
import {
    Card,
    CardHeader,
    Button,
    Tooltip,
    CardContent,
    Typography,
    Box,
    Grid,
    CircularProgress, // Import CircularProgress
    useTheme,
    Divider,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const ListProvider = ({provideData}) => {
    const theme = useTheme();
    const handleRedirect = () => {
        window.open("https://media1.tenor.com/m/yheo1GGu3FwAAAAd/rick-roll-rick-ashley.gif", '_blank'); // Open dummy page
        //navigate('/device-info'); // To replace with the root Device Info
    };

    // Show a loading spinner if data is not yet available
    if (!provideData || provideData.length === 0) {
        return (
            <Card>
                <CardHeader
                    sx={{
                        backgroundColor: "#60bbd6ff",
                        color: "white",
                        padding: "8px 16px",
                    }}
                    title="Liste des provider_customer (0)"
                    titleTypographyProps={{variant: "h6", fontSize: "0.8rem"}}
                />
                <CardContent>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress/> {/* Loading spinner */}
                    </Box>
                    <Typography variant="body2" align="center" sx={{mt: 2}}>
                        Loading data...
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card>
            <CardHeader
                sx={{
                    backgroundColor: "#60bbd6ff",
                    color: "white",
                    padding: "8px 16px",
                }}
                title={`Liste des provider_customer (${provideData.length})`}
                titleTypographyProps={{variant: "h6", fontSize: "0.8rem"}}
            />
            <CardContent>
                {provideData.map((provider, index) => (
                    <Box key={provider.provider_id || index} sx={{mb: 2}}>
                        <Grid container spacing={2} sx={{mb: 1}}>
                            <Grid item xs={4}>
                                <Typography
                                    variant="body2"
                                    color={theme.palette.text.secondary}
                                >
                                    Provider
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    variant="body2"
                                    color={theme.palette.text.secondary}
                                >
                                    Type d'appareil
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    variant="body2"
                                    color={theme.palette.text.secondary}
                                >
                                    Nbre.Act./TOT
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography
                                    variant="body1"
                                    color={theme.palette.primary.main}
                                    fontWeight="bold"
                                >
                                    {provider.provider_name || "N/A"}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {provider.appareils?.map((appareil, idx) => (
                                    <Grid
                                        container
                                        key={idx}
                                        spacing={2}
                                        alignItems="center"
                                        sx={{mb: 1}}
                                    >
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="body1"
                                                color={theme.palette.primary.main}
                                            >
                                                {appareil.type || "N/A"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography
                                                variant="body1"
                                                color={theme.palette.primary.main}
                                            >
                                                {`${appareil.nombre_actif}/${appareil.nombre_total}` || "N/A"}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={2}
                                            sx={{display: "flex", justifyContent: "flex-end"}}
                                        >
                                            <Tooltip
                                                title="Afficher les informations additionnelles du provider du device"
                                                arrow
                                            >
                                                <Button
                                                    variant="outlined"
                                                    onClick={handleRedirect} // Redirect on click to external URL
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "right",
                                                        color: theme.palette.primary.main,
                                                        border: "none",
                                                        "&:hover": {
                                                            backgroundColor: "transparent",
                                                        },
                                                    }}
                                                >
                                                    <ChevronLeftIcon sx={{color: "#4ebcc3"}}/>
                                                    <ChevronRightIcon sx={{color: "#4ebcc3"}}/>
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        {index < provideData.length - 1 && (
                            <Divider sx={{mt: 2, mb: 2}}/>
                        )}
                    </Box>
                ))}
            </CardContent>
        </Card>
    );
};

export default ListProvider;
