import GeneralInfos from "./GeneralInfos";
import InformationsAppareils from "./InformationsAppareils";
import InformationContrats from "./InformationContrats";
import ListProviders from "./ListProvider";
import InformationPdl from "./InformationPdl";
import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  TextField,
  Select,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { getCustomerScreen } from "../../../services/customerService";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RefreshIcon from "@mui/icons-material/Refresh";
import { formatToPdlNumber } from "../../../utils/numberUtils";

const DetailCustomer = () => {
  const { customerId } = useParams(); // Get id from URL params
  const [customerData, setCustomerData] = useState({});
  const [pdlDescription, setPdlDescription] = useState({});
  const theme = useTheme();
  const navigate = useNavigate();
  const [activePdl, setActivePdl] = useState(null);

  const fetchCustomerData = useCallback(async (customerId) => {
    try {
      const customer = await getCustomerScreen(customerId);
      setCustomerData(customer || {});
      setActivePdl(customer.informations_generales.etat_d_inscription);
      console.log("Fetched customer data:", customer);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  }, []);

  useEffect(() => {
    if (customerId) {
      fetchCustomerData(customerId);
    }
  }, [customerId, fetchCustomerData]);

  console.log("Current customerData:", customerData);

  const handleChange = (event) => {
    const selectedPdl = event.target.value;
    setPdlDescription(selectedPdl);
    navigate(`/detailpdl/${selectedPdl.pdl_id}`);
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                sx={{
                  mr: 2,
                  height: "30px",
                  padding: "8px 16px",
                  fontSize: "25px",
                  borderColor: theme.palette.primary.main,
                  borderRadius: "12px",
                }}
              >
                {`${
                  customerData?.informations_generales?.nom?.toUpperCase() || ""
                }
                                  ${
                                    customerData?.informations_generales?.prenom
                                      ? `${customerData.informations_generales?.prenom
                                          .charAt(0)
                                          .toUpperCase()}${customerData.informations_generales?.prenom
                                          .slice(1)
                                          .toLowerCase()}`
                                      : ""
                                  }`}
              </Button>

              {customerData.informations_pdls &&
              customerData.informations_pdls.length > 0 ? (
                customerData.informations_pdls.length === 1 ? (
                  <TextField
                    variant="outlined"
                    value={
                      formatToPdlNumber(
                        customerData.informations_pdls[0]?.pdl
                      ) || "N/A"
                    }
                    InputProps={{
                      readOnly: true,
                      sx: {
                        backgroundColor: "white",
                        width: "auto",
                        minWidth: "200px",
                        color: theme.palette.primary.main,
                        textAlign: "center",
                        border: `2px solid ${theme.palette.primary.main}`,
                        borderRadius: "12px",
                        height: "30px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      },
                    }}
                  />
                ) : (
                  <Select
                    value={pdlDescription.pdl_name_nbdevices || ""}
                    onChange={handleChange}
                    IconComponent={ArrowDropDownIcon}
                    displayEmpty
                    sx={{
                      mr: 2,
                      width: "auto",
                      minWidth: "200px",
                      border: `2px solid ${theme.palette.primary.main}`,
                      borderRadius: "12px",
                      height: "30px",
                      ".MuiSelect-icon": { color: "#24395eff" },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  >
                    <option value="" disabled>
                      S
                    </option>
                    {customerData.informations_pdls.map((pdl, index) => (
                      <option key={index} value={pdl.pdl}>
                        {`PDL: ${pdl.pdl}`}
                      </option>
                    ))}
                  </Select>
                )
              ) : null}
            </Box>
          </Box>
        </Grid>
        <Box display="flex" alignItems="center" sx={{ marginLeft: "auto" }}>
          <Typography sx={{ mr: 2, color: theme.palette.primary.main }}>
            Dernière actualisation : {new Date().toLocaleString("fr-FR")}
          </Typography>
          <RefreshIcon
            onClick={() => window.location.reload()}
            style={{ cursor: "pointer" }}
          />
        </Box>

        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Typography
                sx={{ fontSize: "50px", color: theme.palette.primary.main }}
              >
                <Typography
                  component="span"
                  sx={{ fontSize: "50px", fontWeight: "bold" }}
                >
                  {customerData.informations_generales?.nom?.toUpperCase() ||
                    ""}
                </Typography>{" "}
                {customerData.informations_generales?.prenom
                  ? `${customerData.informations_generales?.prenom
                      .charAt(0)
                      .toUpperCase()}${customerData.informations_generales?.prenom
                      .slice(1)
                      .toLowerCase()}`
                  : ""}
              </Typography>
              <Box display="flex" alignItems="center" sx={{ ml: 2 }}>
                <Typography sx={{ color: theme.palette.primary.main, mr: 1 }}>
                  {activePdl ? "Activé" : "Désactivé"}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    border: `2px solid ${activePdl ? "green" : "red"}`,
                  }}
                >
                  <PowerSettingsNewIcon
                    style={{ color: activePdl ? "green" : "red" }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <GeneralInfos pdl_id={customerId} customerData={customerData} />
          </Grid>
          <Grid item xs={12} md={4}>
            <InformationPdl pdlData={customerData?.informations_pdls} />
            <Box sx={{ height: 16 }} />
            <InformationContrats
              contractsData={customerData?.informations_contrats}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InformationsAppareils
              appData={customerData?.informations_appareils}
            />
            <Box sx={{ height: 16 }} />
            <ListProviders
              provideData={customerData?.informations_fournisseurs}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailCustomer;
