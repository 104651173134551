import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { formatToPdlNumber } from "../../../utils/numberUtils";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#283c5c",
    whiteSpace: "pre-wrap",
  },
});

const HoverRenderer = (props) => {
  const { formattedText } = props;

  const formattedValue = props.value
    ? props.colDef.field === "last_name"
      ? props.value?.toUpperCase()
      : props.colDef.field === "pdl_name"
      ? formatToPdlNumber(props.value)
      : props.value
    : "";

  return (
    <span
      className="missionSpan"
      style={{ position: "relative", display: "inline-block" }}
    >
      <CustomTooltip title={formattedText}>
        <span>{formattedValue}</span>
      </CustomTooltip>
    </span>
  );
};

export default HoverRenderer;
