import React from "react";
import { Switch, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material";

const CustomSwitch = styled(Switch)(({ theme, ownerState }) => ({
  width: 100,
  height: 44,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    padding: 10,
    "&.Mui-checked": {
      transform: "translateX(50px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.success.main,
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 44 / 2,
    backgroundColor: theme.palette.primary.deactivated,
    opacity: 1,
    position: "relative",
    "&:before": {
      content: ownerState.checked ? '"ON"' : '""',
      position: "absolute",
      left: 10,
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: 14,
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    "&:after": {
      content: !ownerState.checked ? '"OFF"' : '""',
      position: "absolute",
      right: 10,
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: 14,
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
  },
}));

const CustomizedSwitch = ({ checked, onChange }) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <CustomSwitch
        checked={checked}
        onChange={onChange}
        theme={theme}
        ownerState={{ checked }}
      />
    </Box>
  );
};

export default CustomizedSwitch;
