import React, { useState } from "react";
import CustomTooltip from "./CustomTooltip";
import { formatToPdlNumber } from "../../../utils/numberUtils";

const PdlNameRenderer = (props) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const pdlId = props.data.id ? props.data.id : "";

  return (
    <span
      className="missionSpan"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      style={{
        position: "relative",
        display: "inline-block",
        textDecoration: "underline",
      }}
    >
      <CustomTooltip title={pdlId}>
        <a
          href={`/detailpdl/${props.data?.id}`}
          style={{
            textDecoration: hover ? "underline" : "none",
            color: "black",
            cursor: "pointer",
          }}
        >
          {formatToPdlNumber(props.value)}
        </a>
      </CustomTooltip>
    </span>
  );
};

export default PdlNameRenderer;
