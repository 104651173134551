import axios from "axios";

const getPDL = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/pdlView`
    );
    const pdls = response.data;
    return pdls;
  } catch (error) {
    console.error("Error fetching PDLs:", error);
    return [];
  }
};

const getPDLById = async (pdl_id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/getPdlDetailsById?pdl_id=${pdl_id}`
    );
    const pdls = response.data;
    return pdls;
  } catch (error) {
    console.error("Error fetching PDLs:", error);
    return [];
  }
};

const getDevicesByPdlId = async (pdl_id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/devicesByPdl?pdl_id=${pdl_id}`
    );
    const pdls = response.data;
    return pdls;
  } catch (error) {
    console.error("Error fetching PDLs:", error);
    return [];
  }
};

const getPdlsByCustomer = async (pdl_id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/pdlByCustomerIdView?customer_id=${pdl_id}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching PDLs:", error);
    return [];
  }
};

const updateActivePdl = async (pdl_id, active) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}/updateStatutPdlView`,
      { pdl_id: pdl_id, active: active }
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error updating PDLs:", error);
    return "";
  }
};

const updateCellPdl = async (updatedData) => {
  axios
    .patch(
      `${process.env.REACT_APP_API_BASE_URL}/updatePdlFieldById`,
      updatedData
    )
    .then((response) => {
      console.log("Data updated successfully:", response.data);
    })
    .catch((error) => {
      console.error("Error updating data:", error);
    });
};

export {
  getPDL,
  getPDLById,
  getDevicesByPdlId,
  getPdlsByCustomer,
  updateActivePdl,
  updateCellPdl,
};
