import axios from "axios";

// Function to get customer information from the API



const getCustomerScreen = async (customerId, token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get_customer_screen?customer_id=${customerId}`, {
      headers: {
        Authorization: `Bearer ${token}` // Pass the token in the headers
      },
    });
    return response.data; // Return the customer data
  } catch (error) {
    console.error("Error fetching customer data:", error.response || error);
    throw error; // Rethrow the error to be caught in DetailCustomer.js
  }
};

// Function to get a list of customers
const getCustomer = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/customerView`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data; // Return the customers data
  } catch (error) {
    console.error("Error fetching Customers:", error);
    return []; // Return an empty array on error
  }
};

// Function to update the active status of a customer
const updateActiveCustomer = async (customer_id, active) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}/updateStatutCustomerView`,
      { customer_id: customer_id, active: active }
    );
    return response.data; // Return the updated data
  } catch (error) {
    console.error("Error updating Customer:", error);
    return ""; // Return an empty string on error
  }
};

// Export the functions
export { getCustomer, updateActiveCustomer, getCustomerScreen };
