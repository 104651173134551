// Fonction pour formater les heures et minutes en "HH:MM"
function formatTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, "0")}:${mins
    .toString()
    .padStart(2, "0")}`;
}

// Fonction pour convertir "HH:MM" en minutes depuis 00:00
const timeToMinutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

// Fonction pour convertir des minutes en "HH:MM"
const minutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, "0")}:${mins
    .toString()
    .padStart(2, "0")}`;
};

// Fonction pour agréger les périodes contiguës ayant le même coefficient
const aggregatePeriods = (periods) => {
  let sortedPeriods = periods
    .map((period) => ({
      ...period,
      startMinutes: timeToMinutes(period.start),
      endMinutes: timeToMinutes(period.end),
    }))
    .sort(
      (a, b) => a.startMinutes - b.startMinutes || a.endMinutes - b.endMinutes
    );

  let result = [];
  let currentPeriod = sortedPeriods[0];

  for (let i = 1; i < sortedPeriods.length; i++) {
    const nextPeriod = sortedPeriods[i];

    if (
      nextPeriod.coefficient === currentPeriod.coefficient &&
      nextPeriod.startMinutes === currentPeriod.endMinutes
    ) {
      currentPeriod.endMinutes = nextPeriod.endMinutes; // Étendre la période courante
    } else {
      result.push({
        coefficient: currentPeriod.coefficient,
        start: minutesToTime(currentPeriod.startMinutes),
        end: minutesToTime(currentPeriod.endMinutes),
      });
      currentPeriod = nextPeriod; // Passer à la prochaine période
    }
  }

  // Ajouter la dernière période au résultat
  result.push({
    coefficient: currentPeriod.coefficient,
    start: minutesToTime(currentPeriod.startMinutes),
    end: minutesToTime(currentPeriod.endMinutes),
  });

  return result;
};

// Fonction principale pour générer les agrégations de coefficients
const generateAggregations = (coefficients) => {
  let result = [];
  let startMinutes = 0; // Début en minutes (00h00 = 0 minutes)
  let currentCoefficient = coefficients[0];
  let currentStart = startMinutes;

  for (let i = 1; i <= coefficients.length; i++) {
    if (coefficients[i] !== currentCoefficient || i === coefficients.length) {
      let endMinutes = startMinutes;
      result.push({
        coefficient: currentCoefficient,
        start: formatTime(currentStart),
        end: formatTime(endMinutes),
      });

      // Mettre à jour pour la prochaine itération
      currentCoefficient = coefficients[i];
      currentStart = endMinutes;
    }
    startMinutes += 15; // Avancer de 15 minutes à chaque itération
  }

  return result;
};

// Fonction pour vérifier la continuité des périodes
const verifyContinuity = (periods) => {
  for (let i = 0; i < periods.length - 1; i++) {
    const currentEnd = periods[i].end;
    const nextStart = periods[i + 1].start;

    if (currentEnd !== nextStart) {
      console.log(
        `Discontinuité détectée entre ${periods[i].end} et ${
          periods[i + 1].start
        }`
      );
      return false; // Discontinuité détectée
    }
  }
  console.log("Toutes les périodes sont continues.");
  return true;
};

export { generateAggregations, verifyContinuity, aggregatePeriods };
