import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import RestoreIcon from "@mui/icons-material/Restore";
import UploadAlert from "../../layout/UploadAlert";
import { useTheme } from "@mui/material/styles";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReactDOMServer from "react-dom/server";

// Modifier les icones de tri d'AGGRID
const CustomAscIcon = ReactDOMServer.renderToString(
  <KeyboardArrowUpIcon style={{ fontSize: "16px" }} />
);
const CustomDescIcon = ReactDOMServer.renderToString(
  <KeyboardArrowDownIcon style={{ fontSize: "16px" }} />
);

const AgGrid = ({
  gridRef,
  rowData,
  columnDefs,
  title,
  onRowClicked,
  search,
  onCellValueChange,
  filterColumns,
  gridId,
}) => {
  const theme = useTheme();
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [filteredRowCount, setFilteredRowCount] = useState(rowData.length);
  const gridOptions = {
    icons: {
      sortAscending: CustomAscIcon,
      sortDescending: CustomDescIcon,
    },
    suppressDragLeaveHidesColumns: true,
    suppressMovableColumns: true,
    multiSortKey: "ctrl",
  };

  useEffect(() => {
    if (gridRef.current) {
      setFilteredRowCount(rowData.length);
    }
  }, [rowData]);

  // useEffect(() => {
  //   if (gridRef.current) {
  //     restoreGridState();
  //   }
  // }, [gridRef.current]);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    gridRef.current.api.onFilterChanged();
  };

  // Function to handle filter changes
  const handleFilterChanged = () => {
    const filteredRowCount = gridRef.current.api.getDisplayedRowCount(); // Get the number of filtered rows
    setFilteredRowCount(filteredRowCount);
    saveGridState();
  };

  const handleSortChanged = () => {
    saveGridState();
  };

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    setOpen(true);
    handleClick();
  };

  const handleClick = () => {
    setOpen(true);
  };

  const isExternalFilterPresent = () => {
    return searchText !== "";
  };

  const doesExternalFilterPass = (node) => {
    const search = searchText.toLowerCase();
    return filterColumns.some((col) => {
      const cellValue = node.data[col];
      const cellValueStr = cellValue ? cellValue.toString().toLowerCase() : "";
      return cellValueStr.includes(search);
    });
  };

  const onCellValueChanged = (params) => {
    console.log("Cell value changed:", params);
    if (onCellValueChange) {
      onCellValueChange(params);
    }
  };

  const saveGridState = () => {
    const columnState = gridRef.current.api.getColumnState(); // Obtenir l'état des colonnes, y compris la largeur
    const filterState = gridRef.current.api.getFilterModel(); // Obtenir les filtres actifs
    const gridConfig = {
      columns: columnState,
      filters: filterState,
    };

    localStorage.setItem(`gridConfig_${gridId}`, JSON.stringify(gridConfig));
  };

  const restoreGridState = () => {
    const savedConfig = localStorage.getItem(`gridConfig_${gridId}`);
    if (savedConfig) {
      const { columns, filters } = JSON.parse(savedConfig);

      // Restaurer l'état des colonnes, y compris la largeur
      gridRef.current.api.applyColumnState({
        state: columns,
        applyOrder: true,
      });

      // Restaurer les filtres
      gridRef.current.api.setFilterModel(filters);
    }
  };

  const resetGridState = () => {
    // Réinitialiser les filtres et colonnes
    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.resetColumnState();
    localStorage.removeItem(`gridConfig_${gridId}`);
  };

  // Fonction pour gérer les changements d'ordre des colonnes et sauvegarder l'état de la grille
  const handleColumnMoved = () => {
    saveGridState();
  };

  // Fonction pour gérer les changements de largeur des colonnes et sauvegarder l'état de la grille
  const handleColumnResized = () => {
    saveGridState();
  };

  return (
    <Card
      sx={{
        backgroundColor: "#f3f3f3",
        margin: "20px",
        padding: "20px",
        height: "800px",
        boxShadow: "lg",
      }}
    >
      <CardContent style={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mb: 2,
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color={theme.palette.primary.main}
          >
            Liste des {title} ({filteredRowCount}){" "}
            {/* Display filteredRowCount */}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <TextField
              variant="standard"
              placeholder={search}
              value={searchText}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => gridRef.current.api.onFilterChanged()}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<DownloadIcon />}
              onClick={onExportClick}
            >
              Exporter
            </Button>
            {/* <Button
              component="label"
              role={undefined}
              variant="contained"
              color="secondary"
              startIcon={<RestoreIcon />}
              onClick={resetGridState}
            >
              Réinitialiser
            </Button> */}
          </Box>
        </Box>

        <Card
          sx={{
            backgroundColor: "#ffffff",
            margin: "20px",
            padding: "20px",
            height: "640px",
            boxShadow: "lg",
          }}
        >
          {!rowData || rowData.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "640px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <CardContent
              style={{ height: "100%", maxHeight: "640px", overflow: "auto" }}
            >
              <div
                className="ag-theme-alpine"
                style={{ height: "100%", width: "100%" }}
              >
                <AgGridReact
                  enableCellTextSelection={true}
                  ref={gridRef}
                  gridOptions={gridOptions}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  onRowClicked={onRowClicked}
                  isExternalFilterPresent={isExternalFilterPresent}
                  doesExternalFilterPass={doesExternalFilterPass}
                  onFilterChanged={handleFilterChanged}
                  onSortChanged={handleSortChanged}
                  onColumnMoved={handleColumnMoved}
                  onColumnResized={handleColumnResized}
                  onCellValueChanged={onCellValueChanged}
                  domLayout="normal"
                />
              </div>
            </CardContent>
          )}
        </Card>
        <UploadAlert open={open} setOpen={setOpen} />
      </CardContent>
    </Card>
  );
};

export default AgGrid;
