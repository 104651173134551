import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "./CustomTooltip";
import { formatToPdlNumber } from "../../../utils/numberUtils";

const PdlCellRenderer = (props) => {
  const formattedText =
    props.value.length && props.value[0].pdl_id
      ? props.value[0].pdl_id.split(", ").join("\n")
      : "";
  const navigate = useNavigate();

  const handlePdlClick = (pdl_id) => {
    navigate(`/detailpdl/${pdl_id}`);
  };

  return (
    <Tooltip
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 0],
            },
          },
        ],
      }}
      placement="right"
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          className="missionSpan"
          style={{
            position: "relative",
            display: "inline-block",
            cursor: "pointer",
          }}
        >
          {props.value.length === 1 ? (
            // Si un seul PDL, rendre le PDL cliquable
            <CustomTooltip title={formattedText}>
              <a
                href={`/detailpdl/${props.value[0].pdl_id}`}
                style={{
                  textDecoration: "underline",
                  color: "black",
                  cursor: "pointer",
                }}
              >
                {formatToPdlNumber(props.value[0].pdl)}
              </a>
            </CustomTooltip>
          ) : (
            // Si plusieurs PDLs, afficher le tooltip avec la liste des PDLs cliquables
            <CustomTooltip
              title={
                <div>
                  {props.value.map((pdlObj) => (
                    <div
                      key={pdlObj.pdl_id}
                      onClick={() => handlePdlClick(pdlObj.pdl_id)}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        marginBottom: "5px",
                      }}
                    >
                      {formatToPdlNumber(pdlObj.pdl)}
                    </div>
                  ))}
                </div>
              }
            >
              <span>{props.value.length.toString() + " pdls"}</span>
            </CustomTooltip>
          )}
        </span>
      </div>
    </Tooltip>
  );
};

export default PdlCellRenderer;
