function formatToPdlNumber(input) {
    const isValid = /^\d{14}$/.test(input);
    if (!isValid) {
        return input;
    }
    const formattedPdl = `${input.slice(0, 2)} ${input.slice(
        2,
        5
    )} ${input.slice(5, 8)} ${input.slice(8, 11)} ${input.slice(11)}`;
    return formattedPdl;
}

function formatToPhoneNum(input) {
    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, '');

    // Format the number, adding a space after every second digit
    const formattedNum = cleaned.split('').reduce((acc, digit, index) => {
        if (index % 2 === 0 && index !== 0) {
            return acc + ' ' + digit;
        }
        return acc + digit;
    }, '');

    return formattedNum;
}

export {formatToPdlNumber, formatToPhoneNum};
//This file is to format numbers to look more easy readable