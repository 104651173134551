import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import CustomTooltip from "./CustomTooltip";

const OnOffRenderer = (props) => {
  const dateString = props.data.last_status_change_date;
  let formattedDate = "";

  if (dateString) {
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      formattedDate = `${String(date.getDate()).padStart(2, "0")}/${String(
        date.getMonth() + 1
      ).padStart(2, "0")}/${date.getFullYear()} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
    }
  }

  return (
    <CustomTooltip title={formattedDate}>
      <span
        className="missionSpan"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          height: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <CircleIcon
            style={{
              color: props.value ? "green" : "red",
              fontSize: "16px",
            }}
          />
        </div>
      </span>
    </CustomTooltip>
  );
};

export default OnOffRenderer;
