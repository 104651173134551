import React, { useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  FormControlLabel,
  Switch,
  IconButton,
  useTheme,
  Tooltip,
  CardHeader,
  Card,
} from "@mui/material";
import { useParams } from "react-router-dom";
import CustomTextField from "../../layout/CustomTextField";
import BadgeIcon from "@mui/icons-material/Badge";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import {
  /*formatToPdlNumber,*/ formatToPhoneNum,
} from "../../../utils/numberUtils";
import ActionAlert from "../../layout/ActionAlert";

const GeneralInfos = ({ customerData }) => {
  console.log("GeneralInfos - customerData:", customerData);

  const theme = useTheme();
  const { customerId } = useParams(); // Get id from URL params
  const [open, setOpen] = useState(false);
  const [/*openTooltip,*/ setOpenTooltip] = useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };
  const handleChange = (newValue, fieldName) => {};
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(customerId)
      .then(() => {
        setOpenTooltip(true);
        setTimeout(() => setOpenTooltip(false), 1000);
        console.log(`Copied to clipboard: ${customerId}`);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleDownloadJson = () => {
    const jsonData = JSON.stringify(customerData, null, 2);
    const blob = new Blob([jsonData], { type: "application/json" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "customerData.json";
    link.click();
  };

  // Extract relevant customer details from API data
  const { nom, prenom, email, adresse, telephone_fixe, telephone_mobile } =
    customerData?.informations_generales || {};
  //const pdl = customerData?.informations_pdls?.[0]?.pdl || "N/A"; // Getting the PDL from the first PDL in the list

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#f6c64f",
          color: theme.palette.primary.main,
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
        }}
        title="Informations générales"
        titleTypographyProps={{ variant: "h6", fontSize: "0.8rem" }}
        action={
          <>
            {/* First Button: Copy ID with Tooltip */}
            <Tooltip title="Copy ID">
              <IconButton
                aria-label="ID"
                sx={{ padding: "0", alignSelf: "center" }}
                onClick={handleCopyToClipboard}
              >
                <BadgeIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>

            {/* Second Button: Download JSON with Tooltip */}
            <Tooltip title="Afficher l'interface de log crm_initialization_step">
              <IconButton
                aria-label="Download JSON"
                sx={{
                  padding: "0",
                  alignSelf: "center",
                  marginLeft: "8px",
                }}
                onClick={handleDownloadJson} // The logic for downloading JSON
              >
                <DownloadIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>

            <ActionAlert
              open={open}
              setOpen={setOpen}
              message={"ID copied to clipboard!"}
            />
          </>
        }
      />

      <Box sx={{ p: 2 }}>
        <Typography
          gutterBottom
          variant="subtitle1"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Informations d'identité
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              Etat d'initialisation
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={customerData?.initialization_done || false}
                  disabled
                />
              }
              label={customerData?.initialization_done ? "DONE" : "PENDING"}
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              Activé
            </Typography>
            <FormControlLabel
              control={<Switch checked={customerData?.active || false} />}
              label={customerData?.active ? "ON" : "OFF"}
              labelPlacement="end"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <CustomTextField
              label="Segment"
              value={customerData?.segment || "N/A"}
              readOnly={true}
              onChange={(newValue, fieldName) =>
                handleChange(fieldName, newValue)
              }
              sx={{
                backgroundColor: theme.palette.background.default,
                flex: 1,
                borderBottom: 2,
                borderColor: theme.palette.primary.main,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label="Nom customer parent"
              value={customerData?.parent_name || "N/A"}
              readOnly={true}
              onChange={(newValue, fieldName) =>
                handleChange(fieldName, newValue)
              }
              sx={{
                backgroundColor: theme.palette.background.default,
                flex: 1,
                borderBottom: 2,
                borderColor: theme.palette.primary.main,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={2}>
            <CustomTextField
              label="Civilité"
              value={customerData?.civilite || "N/A"}
              fullWidth
              readOnly={true}
              onChange={(newValue, fieldName) =>
                handleChange(fieldName, newValue)
              }
              sx={{
                backgroundColor: theme.palette.background.default,
                flex: 1,
                borderBottom: 2,
                borderColor: theme.palette.primary.main,
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <CustomTextField
              inputLabel="Nom"
              value={nom || "N/A"}
              readOnly={true}
              onChange={(newValue, fieldName) =>
                handleChange(fieldName, newValue)
              }
              sx={{
                backgroundColor: theme.palette.background.default,
                flex: 1,
                borderBottom: 2,
                borderColor: theme.palette.primary.main,
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <CustomTextField
              inputLabel="Prénom"
              fieldName="prénom"
              value={prenom || "N/A"}
              readOnly={true}
              onChange={(newValue, fieldName) =>
                handleChange(fieldName, newValue)
              }
              sx={{
                backgroundColor: theme.palette.background.default,
                flex: 1,
                borderBottom: 2,
                borderColor: theme.palette.primary.main,
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <CustomTextField
              inputLabel="Société"
              fieldName="Nom de la société"
              value={customerData?.nom_societe || "N/A"}
              readOnly={false}
              onChange={(newValue, fieldName) =>
                handleChange(fieldName, newValue)
              }
              sx={{ marginRight: 2, flex: 1 }}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 3 }}>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            color={theme.palette.primary.main}
            sx={{ fontWeight: "bold" }}
          >
            Adresse Client
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomTextField
                  label="Adresse"
                  value={adresse || "N/A"}
                  InputProps={{ readOnly: true }}
                  onChange={(newValue, fieldName) =>
                    handleChange(fieldName, newValue)
                  }
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    flex: 1,
                    borderBottom: 2,
                    borderColor: theme.palette.primary.main,
                  }}
                />
                <IconButton
                  onClick={() => handleCopy(adresse || "N/A")}
                  sx={{
                    color: theme.palette.primary.borderBottom,
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomTextField
                  inputLabel="Adresse normalisée + INSEE"
                  value={customerData?.adresse_normalisee || "N/A"}
                  InputProps={{ readOnly: true }}
                  onChange={(newValue, fieldName) =>
                    handleChange(fieldName, newValue)
                  }
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    flex: 1,
                    borderBottom: 2,
                    borderColor: theme.palette.primary.main,
                  }}
                />
                <IconButton
                  onClick={() => handleCopy(adresse || "N/A")}
                  sx={{
                    color: theme.palette.primary.borderBottom,
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            color={theme.palette.primary.main}
            sx={{ fontWeight: "bold" }}
          >
            Informations de contact
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomTextField
                  inputLabel="Mail"
                  value={email || "N/A"}
                  readOnly={false}
                  onChange={(newValue, fieldName) =>
                    handleChange(fieldName, newValue)
                  }
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    flex: 1,
                    //borderBottom: 2,
                    borderColor: theme.palette.primary.borderBottom,
                  }}
                />
                <IconButton
                  onClick={() => handleCopy(email || "N/A")}
                  sx={{
                    color: theme.palette.primary.borderBottom,
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomTextField
                  inputLabel="Telephone fixe"
                  value={formatToPhoneNum(telephone_fixe || "N/A")}
                  readOnly={false}
                  onChange={(newValue, fieldName) =>
                    handleChange(fieldName, newValue)
                  }
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    flex: 1,
                    //borderBottom: 2,
                    borderColor: theme.palette.primary.borderBottom,
                  }}
                />
                <IconButton
                  onClick={() => handleCopy(telephone_fixe || "N/A")}
                  sx={{
                    color: theme.palette.primary.borderBottom,
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CustomTextField
                  inputLabel="Telephone mobile"
                  value={formatToPhoneNum(telephone_mobile || "N/A")}
                  readOnly={false}
                  onChange={(newValue, fieldName) =>
                    handleChange(fieldName, newValue)
                  }
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    flex: 1,
                    //borderBottom: 2,
                    borderColor: theme.palette.primary.borderBottom,
                  }}
                />
                <IconButton
                  onClick={() => handleCopy(telephone_mobile || "N/A")}
                  sx={{
                    color: theme.palette.primary.borderBottom,
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default GeneralInfos;
