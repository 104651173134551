import React, { useEffect, useRef, useState, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import AgGrid from "../common/aggrid/AgGrid";
import { getDevice } from "../../services/deviceService";
import DetailPanel from "./DetailPanel"; // Import the DetailPanel component
import StopPlayRenderer from "../common/renderers/StopPlayRenderer";
import StatusButtonRenderer from "../common/renderers/StatusButtonRenderer";
import OnOffRenderer from "../common/renderers/OnOffRenderer";
import IsMasterRenderer from "../common/renderers/IsMasterRenderer";
import CellActionsRenderer from "../common/renderers/CellActionsRenderer";
import { formatDateTime, dateComparator } from "../../utils/dateUtils";
import HoverRenderer from "../common/renderers/HoverRenderer";
import BooleanDropdownFilter from "../common/filters/BooleanDropdownFilter";
import PdlCellRenderer from "../common/renderers/PdlCellRenderer";
import { useLocation } from "react-router-dom";

const Device = () => {
  const gridRef = useRef(null); // Creating a reference for the AgGrid
  const [rowData, setRowData] = useState([]); // State to store the data for the grid
  const [selectedRowData, setSelectedRowData] = useState(null); // State to store the selected row data
  const [showDetails, setShowDetails] = useState(false); // State to handle detail panel visibility
  const location = useLocation(); // Get location for query params
  const title = "appareils";

  // Defining the column definitions for the devices AgGrid
  const columnDefs = [
    {
      headerName: "Provider",
      field: "provider_name",
      sortable: true,
      filter: "agTextColumnFilter",
      cellRenderer: HoverRenderer,
      cellRendererParams: (params) => {
        return {
          formattedText:
            params.data && params.data.provider_id
              ? params.data.provider_id.split(", ").join("\n")
              : "",
        };
      },
    },
    {
      headerName: "Active",
      field: "active",
      sortable: true,
      cellRenderer: StatusButtonRenderer,
      filter: BooleanDropdownFilter,
      filterParams: {
        trueLabel: "Activé",
        falseLabel: "Désactivé",
      },
      valueFormatter: (params) => (params.value ? "Activé" : "Désactivé"),
    },
    {
      headerName: "Type",
      field: "device_type",
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        multiSelect: true,
        buttons: ["apply", "reset"],
      },
    },
    {
      headerName: "PDL",
      field: "pdls",
      sortable: true,
      filter: false,
      cellRenderer: (params) => (
        <PdlCellRenderer {...params} onPdlClick={handleRowClick} />
      ),
      cellRendererParams: (params) => {
        return {
          formattedText:
            params.data && params.data.pdl_id
              ? params.data.pdl_id.split(", ").join("\n")
              : "",
        };
      },
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const pdlA = nodeA.data.pdlString || "";
        const pdlB = nodeB.data.pdlString || "";

        if (pdlA < pdlB) return -1;
        if (pdlA > pdlB) return 1;
        return 0;
      },
    },
    {
      headerName: "Provider Device ID",
      field: "provider_device_id",
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Statut",
      field: "statut",
      filter: true,
      sortable: true,
      cellRenderer: OnOffRenderer,
      filter: BooleanDropdownFilter,
      filterParams: {
        trueLabel: "ON",
        falseLabel: "OFF",
      },
      valueFormatter: (params) => (params.value ? "ON" : "OFF"),
    },
    {
      headerName: "Der. modification",
      field: "last_status_change_date",
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: formatDateTime,
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          return dateComparator(filterLocalDateAtMidnight, cellValue);
        },
      },
    },
    {
      headerName: "Plan de prog.",
      field: "include_in_heating_plan",
      filter: true,
      sortable: true,
      cellRenderer: StopPlayRenderer,
      filter: BooleanDropdownFilter,
      filterParams: {
        trueLabel: "ON",
        falseLabel: "OFF",
      },
      valueFormatter: (params) => (params.value ? "ON" : "OFF"),
    },

    {
      headerName: "Plan effacement",
      field: "include_in_curtailment",
      filter: true,
      sortable: true,
      cellRenderer: StopPlayRenderer,
      filter: BooleanDropdownFilter,
      filterParams: {
        trueLabel: "ON",
        falseLabel: "OFF",
      },
      valueFormatter: (params) => (params.value ? "ON" : "OFF"),
    },
    {
      headerName: "MaxPowerTheory",
      field: "max_power_theory",
      sortable: false,
      cellStyle: { textAlign: "right" },
      valueFormatter: (params) => {
        return params.value === 0 ? "" : params.value;
      },
    },
    {
      headerName: "MaxPowerReal",
      field: "max_power_real",
      sortable: false,
      cellStyle: { textAlign: "right" },
      valueFormatter: (params) => {
        return params.value === 0 ? "" : params.value;
      },
    },
    {
      headerName: "Is Master",
      field: "is_master",
      sortable: false,
      cellRenderer: IsMasterRenderer,
    },
    {
      field: "creation_date",
      headerName: "Creation Date",
      filter: "agDateColumnFilter",
      valueFormatter: formatDateTime,
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          return dateComparator(filterLocalDateAtMidnight, cellValue);
        },
      },
    },
    {
      headerName: "Update Date",
      field: "update_date",
      sortable: false,
      filter: "agDateColumnFilter",
      valueFormatter: formatDateTime,
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          return dateComparator(filterLocalDateAtMidnight, cellValue);
        },
      },
    },
    {
      headerName: "",
      field: "actions",
      cellRenderer: CellActionsRenderer,
      cellClass: "sticky-action-column",
      width: 100,
      pinned: "right",
      cellRendererParams: {
        options: ["Consulter le détail", "Accéder au looker"],
        url: "detailDevice",
        onUpdateRowData: (id, newStatut) => {
          setRowData((prevData) =>
            prevData.map((row) =>
              row.id === id ? { ...row, active: newStatut } : row
            )
          );
        },
      },
    },
  ];

  // Function to fetch device data based on query params
  const onGetDeviceData = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams(location.search); // Get query params
      const pdl_id = queryParams.get("pdl_id"); // Extract pdl_id if present

      let data;
      if (pdl_id) {
        // Fetch data for the specific pdl_id
        data = await getDevice(pdl_id);
      } else {
        // Fetch all devices if no pdl_id is provided
        data = await getDevice();
      }

      const formattedData = data.map((device) => ({
        ...device,
        creation_date: device.creation_date
          ? new Date(device.creation_date)
          : null,
        update_date: device.update_date ? new Date(device.update_date) : null,
        last_status_change_date: device.last_status_change_date
          ? new Date(device.last_status_change_date)
          : null,
        pdlString: device.pdls?.length
          ? device.pdls.map((pdlObj) => pdlObj.pdl).join(",")
          : "",
      }));

      setRowData(formattedData); // Set the data in the state
    } catch (error) {
      console.error("Failed to fetch device data:", error);
    }
  }, [location.search]);

  // Fetch data only once when the component mounts and when the location.search changes
  useEffect(() => {
    onGetDeviceData();
  }, [onGetDeviceData]);

  useEffect(() => {
    window.addEventListener("cellUpdate", handleUpdateCell);

    return () => {
      window.removeEventListener("cellUpdate", handleUpdateCell);
    };
  }, [rowData]);

  const handleUpdateCell = (updatedData) => {
    const updatedRowData = rowData.map((elm) =>
      elm.id === updatedData.detail.id ? updatedData.detail : elm
    );
    setRowData(updatedRowData);
  };

  const handleRowClick = (event) => {
    if (event.data.pdls.length > 1) {
      setSelectedRowData(event.data);
      setShowDetails(true);
    }
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
    setSelectedRowData(null);
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={showDetails ? 8 : 12}>
          <AgGrid
            gridRef={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            title={title}
            search={"Rechercher un provider, un PDL ou type de device"}
            filterColumns={["provider_name", "pdl_name", "device_type"]}
            gridId="device-list"
          />
        </Grid>
        {showDetails && (
          <Grid item xs={4}>
            <DetailPanel
              data={selectedRowData}
              handleClose={handleCloseDetails}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Device;
